import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

const WashspacesPage = ({
  data: {
    datoCmsWashspacesPage: washspaces,
    allDatoCmsWashspacesSection: sections
  }
}) => (
  <Layout>
    <HelmetDatoCms seo={washspaces.seoMetaTags} />
    <section
      className="hero is-large is-page-hero"
      style={{
        backgroundImage: `url(${washspaces.heroBackgroundImage.fixed.src})`
      }}
    >
      <div className="hero-body">
        <div className="container">
          <h1 className="title has-text-white has-text-weight-normal">
            {washspaces.heroTitle}
          </h1>
        </div>
      </div>
    </section>
    <div className="columns is-multiline" style={{ backgroundColor: 'black' }}>
      {sections.edges.map(({ node: section }) => (
        <div key={section.id} className="column is-half has-text-centered">
          <Link
            to={`/washspaces/${section.slug}`}
            className="hero is-large has-hero-background"
            style={{
              backgroundImage: `url(${section.heroImage.fixed.src})`
            }}
          >
            <div className="hero-body">
              <h1 className="title is-size-2 has-text-weight-normal has-text-white">
                {section.heroTitle}
              </h1>
            </div>
          </Link>
        </div>
      ))}
    </div>
  </Layout>
)

WashspacesPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default WashspacesPage

export const query = graphql`
  query WashspacesQuery {
    datoCmsWashspacesPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          height: 600
          imgixParams: {
            h: "800"
            auto: "compress"
            fm: "pjpg"
            fit: "crop"
            crop: "focalpoint"
            bri: -10
          }
        ) {
          src
        }
      }
    }
    allDatoCmsWashspacesSection {
      edges {
        node {
          id
          slug
          heroTitle
          heroImage {
            fixed(
              width: 800
              imgixParams: {
                auto: "compress"
                fm: "pjpg"
                crop: "entropy"
                bri: -10
              }
            ) {
              src
            }
          }
        }
      }
    }
  }
`
